<template>
  <TepmplateBlock
    mod-class
    content-class="scroll-container"
    title-page="Профиль"
  >
    <vuescroll>
      <div class="scroll-container">
        <div class="admin-page main-container">
          <div
            v-if="
              isIntroPassed === 'false' &&
                !isPhoneExist &&
                isGeneralInfoExist === 'false'
            "
            class="intro-tip p-2 w-50 text-center"
          >
            Введите номер телефона, сайт компании и нажмите кнопку "Сохранить"
          </div>

          <h2 class="admin-page__main-title">
            Профиль компании
            <span class="font-weight-bold">{{ getCompanyTitle }}</span>
          </h2>
          <div v-if="mainCompanyInfoV2">
            <label class="file-select">
              <div v-if="!image" ref="preview" class="select-button"></div>
              <img
                v-else
                class="preview-image admin-page__avatar"
                :src="image"
                alt=""
              />
              <input
                v-if="resivedData.profile_picture === null"
                type="file"
                @change="handleFileChange"
              />
            </label>

            <div class="form-group">
              <label class="form-group__label phone-label "
                >Телефон<span style="color: red">*</span></label
              >

              <input
                type="text"
                class="form-control phone tip-anchor"
                :class="{
                  invalid: phoneNumber && phoneNumber.length < 9
                }"
                v-model="phoneNumber"
                v-phone
                :disabled="isPhoneExist"
              />
            </div>

            <Input
              @input="onInputChange"
              v-model="adminProfile.email"
              label="E-mail*"
              :disabled="
                resivedData && resivedData.email && resivedData.email.length > 0
              "
            />

            <Input
              @input="onInputChange"
              v-model="adminProfile.website"
              label="Сайт"
              :disabled="resivedData.website !== null"
              :noTabindex="isIntroPassed === 'false'"
            />

            <template
              v-if="
                isIntroPassed === 'true' ||
                  (isIntroPassed === 'false' && isPhoneExist)
              "
            >
              <!--class="intro-tip p-2 w-75  text-center"-->
              <div
                v-if="
                  isIntroPassed === 'false' &&
                    isPhoneExist &&
                    isGeneralInfoExist === 'false'
                "
                class="intro-tip p-2 w-50  text-center"
              >
                Теперь зайдите в раздел "Общая информация" и подтвердите данные.
              </div>

              <div class="admin-page__btn-group">
                <!-- <router-link to="/general-info"> -->
                <button
                  @click="infoClick"
                  :disabled="getInfoDisabled"
                  class="btn btn-primary admin-page__btn mb-3 tip-anchor-phone-exist"
                  :class="{
                    'over-top':
                      isIntroPassed === 'false' &&
                      isPhoneExist &&
                      isGeneralInfoExist !== 'true'
                  }"
                >
                  Общая информация
                </button>
                <!-- </router-link> -->
                <!-- <router-link to="/bank-data"> -->

                <div
                  v-if="
                    isIntroPassed === 'false' &&
                      isPhoneExist &&
                      isGeneralInfoExist === 'true' &&
                      isAdminProfileFilled !== 'true'
                  "
                  class="intro-tip p-2 w-50 text-center"
                >
                  Теперь введите банковские данные, сейчас можно
                  <span
                    class="pseudo-link font-weight-bold"
                    @click="doMissBankData"
                    >пропустить</span
                  >
                  этот шаг.
                </div>

                <button
                  @click="bankDataClick"
                  :disabled="getBankDataDisabled"
                  class="btn btn-primary admin-page__btn tip-anchor-general-info"
                  :class="{
                    'over-top':
                      isIntroPassed === 'false' &&
                      isPhoneExist &&
                      isGeneralInfoExist === 'true' &&
                      isAdminProfileFilled !== 'true'
                  }"
                >
                  Банковские данные
                </button>
                <!-- </router-link> -->
              </div>

              <div class="admin-page__add-btn-wrap">
                <div
                  v-if="
                    isIntroPassed === 'false' &&
                      isPhoneExist &&
                      isGeneralInfoExist === 'true' &&
                      isAdminProfileFilled === 'true' &&
                      isOfficeAdded === 'false'
                  "
                  class="intro-tip p-2 w-50 text-center"
                >
                  Необходимо добавить первый офис
                </div>
                <div
                  class="admin-page__btn-item"
                  :class="{
                    'over-top':
                      isIntroPassed === 'false' &&
                      isPhoneExist &&
                      isGeneralInfoExist === 'true' &&
                      isAdminProfileFilled === 'true'
                  }"
                >
                  Добавить новый офис
                  <help-popover
                    message="Добавление офисов, складов, удаленных рабочих мест. Это локация к кторорой потом
                      прикрепляется техника и сотрудники. ВНИМАНИЕ! Пока не создан хотя бы 1 офис - не будет возможности
                      добавлять технику и сотрудника."
                  />
                  <!-- <router-link to="/office"> -->
                  <button
                    :disabled="!(isAdminProfileFilled === 'true')"
                    @click="onAddOfficeClick"
                    id="add-office-anchor"
                    class="btn btn-second admin-page__btn-add"
                  >
                    <span class="admin-page__btn-add-wrap">+</span>
                  </button>
                  <!-- </router-link> -->
                </div>

                <div class="admin-page__btn-item">
                  Создать пользователя в рамках своей компании
                  <!-- <router-link to="create-user"> -->
                  <button
                    :disabled="!(isOfficeAdded === 'true')"
                    @click="onCreateUserClick"
                    class="btn btn-second admin-page__btn-add"
                  >
                    <span class="admin-page__btn-add-wrap">+</span>
                  </button>
                  <!-- </router-link> -->
                </div>

                <div class="admin-page__btn-item">
                  Назначить права пользователям
                  <!-- <router-link to="create-user"> -->
                  <button
                    :disabled="!(isOfficeAdded === 'true')"
                    @click="goToOfficesList"
                    class="btn btn-second admin-page__btn-add"
                  >
                    <span class="admin-page__btn-add-wrap">+</span>
                  </button>
                  <!-- </router-link> -->
                </div>
              </div>
            </template>

            <span class="error" v-if="error.length > 0">{{ error }}</span>

            <div class="d-flex align-items-center justify-content-center">
              <button
                v-if="!getSaveBtnDisabled"
                @click="onSubmit"
                class="btn btn-second mt-3 "
                :class="{ 'over-top': isIntroPassed === 'false' }"
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </vuescroll>

    <!--v-if="isIntroPassed === 'false'" -->
    <div v-if="isIntroPassed === 'false'" class="dark-intro-background">
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <!-- <div
          v-if="
            isIntroPassed === 'false' &&
              !isPhoneExist &&
              isGeneralInfoExist === 'false'
          "
          class="intro-tip p-2 w-25 text-center"
        >
          Введите номер телефона, сайт компании и нажмите кнопку "Сохранить"
        </div> -->
        <!--
        <div
          v-if="
            isIntroPassed === 'false' &&
              isPhoneExist &&
              isGeneralInfoExist === 'false'
          "
          class="intro-tip p-2 w-25 text-center"
        >
          Теперь зайдите в раздел "Общая информация" и подтвердите данные.
        </div>
      -->

        <div
          v-if="
            isIntroPassed === 'false' &&
              isAdminProfileFilled === 'true' &&
              isOfficeAdded === 'true'
          "
          class="intro-tip p-2 w-25 text-center"
        >
          Настало время создать вашу первую задачу. Перейдите на главную
          страницу, и далее в раздел "Задачи"
        </div>
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
// import FormSelect from "../../components/Form/FormSelect";
import Input from "../../components/Form/FormElement/Input";
import RequestManager from "../../function/request/RequestManager";
import vuescroll from "vuescroll";
import { GET_BANK_INFO, GET_MAIN_COMPANY_INFO } from "../../store/types";
import { mapActions, mapGetters } from "vuex";
import HelpPopover from "../../components/HelpPopover";
import { doPoseTip } from "@/utils";
import { OfficeApi } from "../../function/request/apiV2";

export default {
  name: "AdminProfile",
  components: {
    HelpPopover,
    Input,
    TepmplateBlock,
    /* FormSelect, */ vuescroll
  },
  computed: {
    ...mapGetters(["currentUser", "mainCompanyInfo", "bankInfo"]),
    getCompanyTitle() {
      return this.mainCompanyInfo?.brand ? this.mainCompanyInfo?.brand : "";
    },
    defaultPhoneCode() {
      const elem = this.options.find(elem => elem.value === this.phoneCode);
      if (elem !== undefined) {
        return elem.value;
      } else {
        return "";
      }
    },

    /* checkStep() {
      let label =
        'Для изменения данных в разделах "Общая информация" и "Банковские данные" вам необходимо связаться с менеджером.' +
        "Теперь нужно добавить хотя бы один офис.";

      if (!this.getSaveBtnDisabled) {
        label = "Пожалуйста заполните поля 'Телефон' и 'Сайт'.";
      }

      if (!this.getInfoDisabled) {
        label = "Необходимо заполнить раздел 'Общая информация'.";
      }

      if (!this.getBankDataDisabled) {
        label =
          'Теперь необходимо заполнить данные раздела "Банковские данные".';
      }

      return label;
    }, */

    getInfoDisabled() {
      if (this.mainCompanyInfo !== undefined) {
        if (this.mainCompanyInfo.ogrn === null) {
          return false;
        }
      }
      return true;
    },
    getSaveBtnDisabled() {
      for (let key in this.resivedData) {
        if (key !== "email" && key !== "id") {
          if (this.resivedData[key]) {
            return true;
          }
        }
      }
      return false;
    },
    getBankDataDisabled() {
      let check = false;
      if (!this.getInfoDisabled || !this.getSaveBtnDisabled) {
        check = true;
      }
      // if (Object.keys(this.notUpdated).length > 0) {
      //   const arr = Object.keys(this.notUpdated);
      //   if (arr.length === 1 && arr[0] == 'profile_picture') {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // }

      for (let key in this.bankInfo) {
        if (key !== "id") {
          if (this.bankInfo[key] && this.bankInfo[key].length > 0) {
            check = true;
          }
        }
      }
      return check;
    },
    image() {
      const url = this.currentUser.profile_picture
        ? this.currentUser.profile_picture
        : this.src;
      return url ? process.env.VUE_APP_URL + url : null;
    }
  },
  async created() {
    this.fetchData();
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
    this.mainCompanyInfoV2 = JSON.parse(
      localStorage.getItem("mainCompanyInfo")
    );
    this.isPhoneExist = !!this.mainCompanyInfoV2.phone;
    this.isGeneralInfoExist = this.mainCompanyInfoV2.ogrn ? "true" : "false";
    this.isAdminProfileFilled = localStorage.getItem("isAdminProfileFilled");

    const itemsOffices = await this.officeApi.api.listOffice({
      companyId: this.mainCompanyInfoV2.companyId,
      limit: 1000000,
      sort: "name"
    });
    this.officesInfo = itemsOffices;
    this.isOfficeAdded = this.officesInfo.count > 0 ? "true" : "false";
  },
  mounted() {
    this.mainCompanyInfoV2 = JSON.parse(
      localStorage.getItem("mainCompanyInfo")
    );
    this.isPhoneExist = this.mainCompanyInfoV2.phone ? "true" : "false";
    this.isGeneralInfoExist = this.mainCompanyInfoV2.ogrn ? "true" : "false";
    this.GET_BANK_INFO();
    this.GET_MAIN_COMPANY_INFO();
    if (this.isIntroPassed === "false") {
      setTimeout(() => {
        console.log("doPoseTip");
        if (this.isAdminProfileFilled === "true") {
          doPoseTip("#add-office-anchor", "top");
        } else {
          if (!this.isPhoneExist) {
            doPoseTip(".tip-anchor", "top");
          } else {
            if (this.isGeneralInfoExist === "true") {
              doPoseTip(".tip-anchor-general-info", "top");
            } else {
              doPoseTip(".tip-anchor-phone-exist", "top");
            }
          }
        }
      }, 100);
    }
  },
  data() {
    return {
      siteDisabled: false,
      officesInfo: {},
      officeApi: new OfficeApi(),
      resivedData: {
        email: "",
        id: 0,
        phone: null,
        profile_picture: null,
        website: null
      },
      notUpdated: {},
      error: "",
      src: "https://placehold.it/97x97",
      selected: "Админ",
      options: [{ text: "+7", value: "+7" }],
      phoneCode: "",
      phoneNumber: "",
      adminProfile: {
        email: "",
        id: 0,
        phone: null,
        profile_picture: null,
        website: null
      },
      isIntroPassed: undefined,
      isGeneralInfoExist: localStorage.getItem("isGeneralInfoExist") || "false",
      isPhoneExist: undefined,
      isAdminProfileFilled: localStorage.getItem("isAdminProfileFilled"),
      isOfficeAdded: "false" //localStorage.getItem("isOfficeAdded")
    };
  },
  methods: {
    ...mapActions({ GET_BANK_INFO, GET_MAIN_COMPANY_INFO }),
    getBtnDisabled() {
      if (!this.getInfoDisabled) {
        return true;
      }
      if (!this.getBankDataDisabled) {
        return true;
      }
      return !this.getSaveBtnDisabled;
    },
    goToOfficesList() {
      this.$router.push({ name: "OfficesList" });
    },
    infoClick() {
      this.$router.push({ path: "/general-info/" + this.mainCompanyInfo.id });
    },
    bankDataClick() {
      this.$router.push({ path: "/bank-data/" + this.mainCompanyInfo.id });
    },
    onCreateUserClick() {
      this.$router.push({ path: "create-user/" + this.mainCompanyInfo.id });
    },
    onAddOfficeClick() {
      this.$router.push({ path: "/office/" + this.mainCompanyInfo.id + "/0" });
    },
    fetchData() {
      RequestManager()
        .getAdminProfile()
        .then(data => {
          const notSaved = { ...data };
          for (let key in notSaved) {
            if (notSaved[key] !== null) {
              delete notSaved[key];
            }
          }
          if (data.website !== null) {
            this.siteDisabled = true;
          }
          this.resivedData = { ...data };
          this.src = data.profile_picture;
          this.notUpdated = notSaved;
          this.adminProfile = data;
          this.phoneCode = data.phone && data.phone.slice(0, 2);
          this.phoneNumber = data.phone;
        });
    },
    onInputChange() {
      this.error = "";
    },
    onCodeSelect(code) {
      this.phoneCode = code;
      this.error = "";
    },
    handleFileChange(e) {
      const src = URL.createObjectURL(e.target.files[0]);
      this.adminProfile.profile_picture = e.target.files[0];
      this.src = src;
      this.error = "";
      this.uploadImg();
    },
    uploadImg() {
      const picture = new FormData();
      picture.append("profile_picture", this.adminProfile.profile_picture);
      RequestManager().updateUserPhoto(this.currentUser.id, picture);
    },
    async doMissBankData() {
      const newBankInfo = Object.assign({}, this.bankInfo);
      Object.keys(this.bankInfo).forEach(key => {
        if (key !== "id") {
          newBankInfo[key] = "1";
        }
      });
      const result = await RequestManager().updateBankInfo(
        this.bankInfo.id,
        newBankInfo
      );
      console.log(result);
      //this.isOfficeAdded = "false";
      this.isAdminProfileFilled = "true";
      localStorage.setItem("isAdminProfileFilled", "true");
      if (this.isIntroPassed === "false") {
        setTimeout(() => {
          doPoseTip("#add-office-anchor", "top");
        }, 300);
      }
    },
    onSubmit() {
      if (this.phoneCode !== null && this.phoneNumber !== null) {
        this.adminProfile.phone = this.phoneNumber
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
          .replaceAll(" ", "");
      }
      let error = "";
      /* for (let key in this.adminProfile) {
        if (this.adminProfile[key] === null && key === "phone") {
          error = "Заполните все поля с информацией";
        }
      } */
      if (this.phoneNumber.length < 9) {
        error = "Заполните все обязательные поля с информацией";
      }
      if (error.length === 0) {
        const reqBody = {
          id: this.adminProfile.id,
          phone: this.phoneNumber
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("-", "")
            .replaceAll(" ", ""),
          email: this.adminProfile.email,
          website: this.adminProfile.website
        };

        RequestManager()
          .updateAdminProfile(this.currentUser.company, reqBody)
          .then(data => {
            this.adminProfile = data;
            this.resivedData = data;
            this.isPhoneExist = true;
            this.fetchData();
            if (this.isIntroPassed === "false") {
              setTimeout(() => {
                doPoseTip(".over-top", "top");
              }, 300);
            }
          });
      } else {
        this.$set(this, "error", error);
      }
    }
  }
  // validations: {
  //   adminProfile: {
  //     profile_picture: {
  //       required
  //     },
  //     phone: {
  //       required
  //     },
  //     website: {
  //       required
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.error {
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: red;
}
.scroll-container {
  width: 500px !important;
  margin: 0 auto;
  overflow: hidden;
}
.admin-page {
  // max-height: 800px;
  margin: 0 auto;
  padding: 38px 72px 36px;
  width: 100%;
  max-width: 498px;
  overflow: hidden;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  position: relative;
  z-index: 1001;
}

.admin-page__main-title {
  margin-bottom: 28px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--card-text-color);
}
.admin-page__select-group {
  display: flex;
  align-items: center;
  width: 100%;

  .custom-select {
    width: 74px;
  }
}
.admin-page__add-btn-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 271px;
}

.admin-page__select {
  height: 66px;
  width: 75px;
  margin-right: 13px;
}

.admin-page__img-load-wrap {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-page__avatar {
  width: 97px;
  height: 97px;
  border-radius: 50%;
}

.admin-page__btn {
  border: 1px solid #006b90;
}

.admin-page__btn-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  width: 271px;
  color: var(--second-main-color);

  &:not(:last-child) {
    margin-bottom: 13px;
  }
}

.admin-page__btn-add-wrap {
  display: block;
  font-size: 0;
  width: 16px;
  height: 16px;
  //background: #FFFFFF;
  color: var(--second-main-color);
  border-radius: 50%;
}

.form-group__label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  color: var(--second-main-color) !important;
}

.admin-page__btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
  a {
    max-height: 50px;
    margin-bottom: 10px;
  }
}

.admin-page__btn {
  max-width: 100%;
  width: 271px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:disabled {
    color: #cecece;
    border: 1px solid #cecece;
    background-color: transparent;
  }
}

.admin-page__btn-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-page__btn-add {
  width: 63px;
  height: 54px;

  background: #006b90;
  border-radius: 14px;

  padding: 14px 23px;

  background-image: url("../../resource/img/plus.svg");
  background-repeat: no-repeat;
  background-position: center;

  &:disabled {
    background: #cecece;
    background-image: url("../../resource/img/plus.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (min-width: 320px) {
  .admin-page {
    margin: 0;
    padding: 0 20px 35px;
  }
}
@media (min-width: 375px) {
  .admin-page {
    margin: 0;
    padding: 0 30px 25px;
  }
}
@media (min-width: 425px) {
  .admin-page {
    margin: 0;
    padding: 40px 20px;
  }
}
.file-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 14px;

  color: var(--second-main-color);
}

.file-select > .select-button {
  background-image: url("../../resource/img/edit-ava.svg");
  background-repeat: no-repeat;
  font-size: 10px;
  width: 97px;
  height: 97px;
  cursor: pointer;
  margin-right: 17px;
}

.file-select > input[type="file"] {
  display: none;
}

.phone-label.over-top {
  color: #fff !important;
}

.pseudo-link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.font-weight-bold {
  font-weight: bold;
}

.phone-label {
  font-size: 14px;
  font-weight: 500;
}
.phone-label.over-top {
  color: #fff !important;
}
.phone {
  background: var(--auth-input-bg);
  border: 1px solid var(--auth-input-bg);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
  color: var(--main-text-color);
}
</style>
